module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-XYZ-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en"}],"fields":[{"name":"bio","store":true},{"name":"hideIndex","store":true},{"name":"registrationRequired","store":true},{"name":"slug","store":true},{"name":"title","store":true},{"name":"date","store":true},{"name":"content","store":false},{"name":"relativeDirectory","store":true},{"name":"excerpt","store":true},{"name":"author","store":true},{"name":"articleCategory","store":true}],"resolvers":{"MarkdownRemark":{}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
