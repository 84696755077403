import { css } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import SubMenu from './SubMenu';
import { Box, Flex, BoxVisible, Container, BoxInline } from '../../../style/basicStyle';
import colors from '../../../utils/colors';

const displayHidden = css`
  display: none !important;
`;

const w48 = css`
  width: 12rem;
`;

const bgDark = css`
  background: ${colors.secondary};
`;

const bgLight = css`
  background: ${colors.primary};
  opacity: 0.85;
`;

const absolute = css`
  position: absolute;
`;

const dropdownMenu = css`
  display: block !important;
  border-radius: 5px;
  width: 100%;
`;

// eslint-disable-next-line react/prop-types
const Menu = ({ isVisible, childLinks, childLinkStyling, navDarkActive, toggleNav, authUser, requireAuth, requireAdmin, hideWithAuth }) => {
  return (
    <Box css={[!isVisible && displayHidden, absolute, w48, childLinkStyling]}>
      <Box mt={[1]} px={[1]} py={[1]} css={[(navDarkActive && bgDark) || bgLight, dropdownMenu]}>
        <SubMenu hideWithAuth={hideWithAuth} toggleNav={toggleNav} authUser={authUser} requireAuth={requireAuth} requireAdmin={requireAdmin} navDarkActive={navDarkActive} childLinks={childLinks} />
      </Box>
    </Box>
  );
};
export default Menu;

Menu.propTypes = {
  isVisible: PropTypes.bool,
  navDarkActive: PropTypes.bool,
  requireAuth: PropTypes.bool,
  requireAdmin: PropTypes.bool,
  hideWithAuth: PropTypes.bool,
  childLinks: PropTypes.arrayOf(
    PropTypes.shape({
      linkName: PropTypes.string,
      link: PropTypes.string,
      categoryName: PropTypes.string
    })
  ).isRequired
};

Menu.defaultProps = {
  isVisible: false,
  navDarkActive: false,
  requireAuth: false,
  hideWithAuth: false,
  requireAdmin: false
};
