import { css } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import SubMenuMobile from './SubMenuMobile';
import { Box, BoxInlineBlock, Flex, BoxVisible, Container, BoxInline } from '../../../style/basicStyle';

const displayHidden = css`
  display: none !important;
`;

const w48 = css`
  width: 12rem;
`;

const wFull = css`
  width: 100%;
`;

const moveRight = css`
  text-align: right;
`;

const dropdownMenu = css`
  display: block !important;
`;

const menuMobile = css`
  text-align: right !important;
`;

// eslint-disable-next-line react/prop-types
const MenuMobile = ({ isVisible, childLinks, childLinkStyling, toggleNav, navDarkActive, authUser, requireAuth, requireAdmin, hideWithAuth }) => {
  return (
    <BoxInlineBlock my={[0]} css={[!isVisible && displayHidden, w48, menuMobile]}>
      <Box css={[dropdownMenu, wFull]}>
        <SubMenuMobile
          hideWithAuth={hideWithAuth}
          toggleNav={toggleNav}
          authUser={authUser}
          requireAuth={requireAuth}
          requireAdmin={requireAdmin}
          navDarkActive={navDarkActive}
          childLinks={childLinks}
        />
      </Box>
    </BoxInlineBlock>
  );
};
export default MenuMobile;

MenuMobile.propTypes = {
  isVisible: PropTypes.bool,
  toggleNav: PropTypes.func.isRequired,
  childLinks: PropTypes.arrayOf(
    PropTypes.shape({
      linkName: PropTypes.string,
      link: PropTypes.string,
      categoryName: PropTypes.string
    })
  ).isRequired
};

MenuMobile.defaultProps = {
  isVisible: false
};
