import React, { Component } from 'react';
import styledSystem from '@emotion/styled';
import { navigate } from 'gatsby';
import { withTheme, ThemeProvider } from 'emotion-theming';
import { css, Global } from '@emotion/core';
import { fontSize } from 'styled-system';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookie from 'universal-cookie';
import { Helmet } from 'react-helmet';
import Script from 'react-load-script';
import SimpleReactLightbox from 'simple-react-lightbox';
import toastFunc from '../utils/toast';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import colors from '../utils/colors';
import LayoutWithAuth from '../components/layoutWithAuth';
import '../style/body.css';
import '../style/toastCustom.css';
import { AuthUserContext } from '../components/Session';

// global style example from https://stackoverflow.com/questions/51637950/enable-global-theming-with-emotion
// for gatsby v1 -> v2, used strategy from https://github.com/gatsbyjs/gatsby/issues/7505

const makeGlobalStyles = (theme) => css`
  * {
    font-family: -apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'helvetica neue', helvetica, roboto, noto, 'segoe ui', arial, sans-serif;
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  a:hover {
    cursor: pointer;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${colors.secondary};
  }
  p {
    color: ${colors.accent};
  }
  body {
    background: ${theme.bg};
  }
`;

const toastCartStyle = css`
  color: black;
`;

const GlobalStyles = withTheme(({ theme }) => <Global styles={makeGlobalStyles(theme)} />);

const FontConfig = styledSystem.div`
  ${fontSize};
`;

const cookie = new Cookie();

const debug = false;

class Layout extends Component {
  constructor(props) {
    super(props);
    const hashCheck = props.location && props.location.hash && props.location.hash.length > 1 && props.location.hash;
    const bannerCookieTimeout = (process.env.GATSBY_DEVELOPMENT && process.env.GATSBY_DEVELOPMENT === 'true' && 30000) || 86400;
    if (hashCheck && hashCheck === '#showExperimental') {
      cookie.set('showExperimental', true, { path: '/', expires: new Date(Date.now() + 2592000) });
    } else if (hashCheck && hashCheck === '#showExperimentalOff') {
      cookie.set('showExperimental', false, { path: '/', expires: new Date(Date.now() + 2592000) });
    } else if (hashCheck && hashCheck === '#resetBanner') {
      cookie.set('bannerCookieTimeout', false, { path: '/', expires: new Date(Date.now() + 3600) });
    } else {
      cookie.set('bannerCookieTimeout', true, { path: '/', expires: new Date(Date.now() + bannerCookieTimeout) });
    }
    const bannerCookieStatus = cookie.get('bannerCookieTimeout');
    debug && console.log('bannerCookieStatus: ', bannerCookieStatus);
    const showBanner = (bannerCookieStatus && bannerCookieStatus === 'true' && true) || false;
    const cookieValExperimental = cookie.get('showExperimental');
    debug && console.log('cookieValExperimental: ', cookieValExperimental);
    const showExperimental = (cookieValExperimental && cookieValExperimental === 'true' && true) || false;
    showExperimental && console.log('*** Experimental features enabled ***');
    this.state = {
      navDarkActive: false,
      showExperimental,
      showBanner
    };
    this.toggleNavDark = this.toggleNavDark.bind(this);
    /* note - hashCheck and state setting for cookie were in componentDidMount */
    debug && console.log('layout/index - constructor');
    console.log(`* Project Red July *`);
  }

  componentDidMount() {
    debug && console.log('layout/index - componentDidMount');
  }


  toggleNavDark(inputObj) {
    const { props } = this;
    const { state } = this;
    // if we receive an object, that means we have been explicitely called by the first render of a component. set the state we are called for by that component.
    if (typeof inputObj !== 'object' || typeof inputObj === 'undefined' || inputObj === undefined) {
      // we are NOT being called by an explicit component render, but a scroll toggle.
      // console.log('PageWrapper ************ toggleNavDark called by visibility event -- inputObj: '+inputObj+' | past state: '+this.state.navDarkActive+' -- doing NOTHING');
      if (inputObj) {
        // the portion of the visibility check is visible - do not show the nav bar
        this.setState({ navDarkActive: false });
      } else {
        // we dont have the visibility check DOM in the screen - switch to dark navbar
        this.setState({ navDarkActive: true });
      }
    } else {
      // process the object and set the state accordingly.
      const hashCheck = props.location && props.location.hash && props.location.hash.length > 1 && props.location.hash;
      const { navBarDark } = inputObj;
      if (hashCheck === '#contact') {
        // automatically set the navBar to dark if we are rendering a hash. this means we are likely scrolled down the page. when this happens, the visibility check for whether or not we should automatically make the navbar light or dark will NOT be triggered, and we'll end up scrolled down with a light, transparent navbar over page content.
        this.setState({ navDarkActive: true });
      } else if (navBarDark && state.navDarkActive) {
        // console.log('PageWrapper ************ toggleNavDark called by render - navBarDark - '+navBarDark+' | inputObj: ',inputObj,' | past state: '+this.state.navDarkActive+' -- states EQUAL -- no change navDarkActive!');
      } else if (!navBarDark && !state.navDarkActive) {
        // console.log('PageWrapper ************ toggleNavDark called by render - navBarDark - '+navBarDark+' | inputObj: ',inputObj,' | past state: '+this.state.navDarkActive+' -- states EQUAL -- no change navDarkActive!');
      } else if (navBarDark && !state.navDarkActive) {
        // console.log('PageWrapper ************ toggleNavDark called by render - navBarDark - '+navBarDark+' | inputObj: ',inputObj,' | past state: '+this.state.navDarkActive+' -- setting navDarkActive to TRUE');
        this.setState({ navDarkActive: true });
      } else if (!navBarDark && state.navDarkActive) {
        // console.log('PageWrapper ************ toggleNavDark called by render - navBarDark - '+navBarDark+' | inputObj: ',inputObj,' | past state: '+this.state.navDarkActive+' -- setting navDarkActive to FALSE');
        this.setState({ navDarkActive: false });
      } else {
        // console.log('PageWrapper ************ toggleNavDark called by render - navBarDark - '+navBarDark+' | inputObj: ',inputObj,' | past state: '+this.state.navDarkActive+' -- doing NOTHING');
      }
    }
  }

  render() {
    const { props } = this;
    const { toggleNavDark } = this;
    const { state } = this;
    const { navDarkActive } = state;
    const { showExperimental } = state;
    const { data } = props;
    const { children } = props;
    const { showBanner } = state;
    debug && console.log('layout/index - rendered');
    const { location } = props;
    const childrenWithProps = React.Children.map(children, (child) =>
      React.cloneElement(child, { toggleNavDark, navDarkActive, location, showExperimental })
    );
    !showBanner &&
      toastFunc(`Project Red July`, {
        className: 'toastcustom',
        onClick: function clicked() {
          navigate('/');
        },
        closeButton: false,
        draggable: false,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        position: 'top-center',
        autoClose: 10000,
        closeOnClick: false,
        disableAutoClose: true
      });
    return (
      <FontConfig fontSize={[2, 3, 4]}>
        <Helmet htmlAttributes={{ lang: 'en' }}>
          <title>Project Red July</title>
        </Helmet>
        <GlobalStyles />
        <h1>ADIOS!</h1>
      </FontConfig>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.objectOf(PropTypes.any), // eslint-disable-line react/require-default-props
  data: PropTypes.objectOf(PropTypes.any) // eslint-disable-line react/require-default-props
};

export default Layout;
