import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
import { width100 } from '../../../../style/genericStyling';
import { Box, Flex, BoxVisible, Container, BoxInline } from '../../../../style/basicStyle';
import colors from '../../../../utils/colors';
import { barHoverDark, exBarBar, exBarContent, exBarItem, exBarLink, navBasic } from '../../../../style/mouseOverUnderline';
import * as ROLES from '../../../../constants/roles';

const bgDark = css`
  background: ${colors.secondary};
`;

const bgLight = css`
  background: ${colors.primary};
`;

const displayBlock = css`
  display: block !important;
`;

const subMenuStyle = css`
  text-align: left;
  float: left;
`;

const categoryStyle = css`
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  font-weight: bold;
  opacity: 0.5;
  --text-opacity: 0.5;
`;
const CategoryName = (item, index) => (
  <>
    <Box mt={[1]} fontSize={[2]} css={[categoryStyle]}>
      {item.categoryName}
    </Box>
  </>
);

const categoryParentStyle = css`
  float: left;
`;

// eslint-disable-next-line react/prop-types
const SubMenu = ({ childLinks, navDarkActive, requireAuth, requireAdmin, hideWithAuth, authUser, toggleNav }) => {
  return (
    <Box fontSize={[3]} style={{ justifyContent: 'left', marginLeft: 'initial'}} css={[displayBlock, subMenuStyle]}>
      {childLinks.map(
        (item, index) =>
          // eslint-disable-next-line react/prop-types
          // Display conditions
          ((!item.requireAuth && !authUser && !item.requireAdmin) || // Does our page not require authorization AND is there no user AND does it not require admin?
            (authUser && !item.hideWithAuth && !item.requireAdmin) || // Does our page require a user AND does our page need to be displayed if the user is logged in AND does it not require admin?
            // eslint-disable-next-line react/prop-types
            (authUser && item.requireAuth && item.requireAdmin && authUser.roles[ROLES.ADMIN])) && ( // Does our page require a user AND does it need to be displayed with a logged in user AND is the user an admin?
            <Fragment key={item.linkName + 'parent' + index}>
              <Box style={{ justifyContent: 'left', marginLeft: 'initial'}} css={item.categoryName && categoryParentStyle || width100} key={`${index}SubMenu`}>
                {(item.categoryName && CategoryName(item, index)) || (
                  <Box style={{ justifyContent: 'left', marginLeft: 'initial' }} mt={[1]}>
                    <Box style={{ justifyContent: 'left', marginLeft: 'initial' }} key={item.linkName + index}>
                      <ul>
                        <li key={item.linkName + 'linkName' + index} style={{ justifyContent: 'left' }} css={[exBarItem, item.linkStyle]}>
                          <Box style={{ justifyContent: 'left' }} css={exBarContent}>
                            {item.linkName}
                          </Box>
                          {(item.link && item.link.indexOf('://') > -1 && (
                            // eslint-disable-next-line react/no-array-index-key
                            <a target="_blank" rel="noreferrer" style={{ justifyContent: 'left' }} href={item.link} css={exBarLink}>
                              <span style={{ justifyContent: 'left' }} css={[navBasic, exBarBar, navDarkActive && barHoverDark]} />
                            </a>
                          )) || (
                            <Link style={{ justifyContent: 'left' }} to={item.link} css={exBarLink}>
                              <span style={{ justifyContent: 'left' }} css={[navBasic, exBarBar, navDarkActive && barHoverDark]} />
                            </Link>
                          )}
                        </li>
                      </ul>
                    </Box>
                  </Box>
                )}
              </Box>
              {item.categoryName && <br />}
            </Fragment>
          )
      )}
    </Box>
  );
};

export default SubMenu;

SubMenu.propTypes = {
  navDarkActive: PropTypes.bool,
  requireAdmin: PropTypes.bool,
  requireAuth: PropTypes.bool,
  hideWithAuth: PropTypes.bool,
  childLinks: PropTypes.arrayOf(
    PropTypes.shape({
      linkName: PropTypes.string,
      link: PropTypes.string,
      categoryName: PropTypes.string
    })
  ).isRequired
};

SubMenu.defaultProps = {
  navDarkActive: false,
  requireAuth: false,
  hideWithAuth: false,
  requireAdmin: false
};
