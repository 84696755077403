import { css } from '@emotion/core';

export const pages = [];

const linkStyleIndented = css`
  margin-left: 1.25rem !important;
`;


const newsEnabled = false;

if (newsEnabled) {
  pages.push({
    linkName: 'News',
    link: '/news'
  });
}

const aboutEnabled = false;

if (aboutEnabled) {
  pages.push({
    linkName: 'About',
    link: '/about',
    childLinks: [
      {
        linkName: 'About',
        link: '/about'
      }
    ]
  });
}

pages.push({
  linkName: 'Log in',
  linkNameSignedIn: 'Account',
  link: '/account',
  childLinkStyling: css`
    left: -100px;
  `,
  childLinks: [
    {
      linkName: 'Sign In',
      link: '/signin',
      hideWithAuth: true,
      requireAuth: false
    },
    {
      linkName: 'Sign Up',
      link: '/signup',
      hideWithAuth: true,
      requireAuth: false
    },
    {
      linkName: 'Account',
      link: '/account',
      requireAuth: true
    },
    {
      categoryName: 'Admin',
      requireAuth: true,
      requireAdmin: true
    },
    {
      linkName: 'VSA Servers',
      link: '/vsaservers',
      requireAuth: true,
      requireAdmin: true,
      linkStyle: linkStyleIndented
    },
    {
      linkName: 'Users',
      link: '/users',
      requireAuth: true,
      requireAdmin: true,
      linkStyle: linkStyleIndented
    },
    {
      linkName: 'Sign Out',
      link: '/signout',
      requireAuth: true
    }
  ]
});

export default pages;
