import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
import colors from '../../../../utils/colors';
import { Box, Flex, BoxVisible, Container, BoxInline } from '../../../../style/basicStyle';
import * as ROLES from '../../../../constants/roles';
import { barHoverDark, exBarBar, exBarContent, exBarItem, exBarLink, navBasic } from '../../../../style/mouseOverUnderline';
import SubMenu from '../../Menu/SubMenu';

const menuCategoryColor = css`
  --text-opacity: 0.5;
  opacity: 0.5;
  color: ${colors.primary};
`;

const displayBlock = css`
  display: block !important;
`;

const subMenuStyle = css`
  text-align: right !important;
  float: right;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
`;

const childLinkStyle = css`
  --text-opacity: 1;
  color: ${colors.primary};
`;

const CategoryName = (item, index) => (
  <Box fontSize={[2]} css={[menuCategoryColor]}>
    {item.categoryName}
  </Box>
);

// eslint-disable-next-line react/prop-types
const SubMenuMobile = ({ childLinks, navDarkActive, requireAuth, requireAdmin, hideWithAuth, authUser, toggleNav }) => {
  return (
    <Box css={[displayBlock, subMenuStyle]}>
      {childLinks.map(
        (item, index) =>
          ((!item.requireAuth && !authUser && !item.requireAdmin) ||
            (authUser && !item.hideWithAuth && !item.requireAdmin) ||
            // eslint-disable-next-line react/prop-types
            (authUser && item.requireAuth && item.requireAdmin && authUser.roles[ROLES.ADMIN])) && (
            // eslint-disable-next-line react/no-array-index-key
            <Box style={{ fontWeight: 'bold' }} key={`${index}SubMenu`}>
              {(item.categoryName && CategoryName(item, index)) || (
                <Box onClick={toggleNav} onKeyPress={toggleNav} mt={[1]}>
                  {/* eslint-disable-next-line react/no-array-index-key */}
                  <Box fontSize={[3]} key={item.linkName + index} my={[2]}>
                    <Link to={`${item.link}`} css={[childLinkStyle]}>
                      {item.linkName}
                    </Link>
                  </Box>
                </Box>
              )}
            </Box>
          )
      )}
    </Box>
  );
};

export default SubMenuMobile;

SubMenuMobile.propTypes = {
  navDarkActive: PropTypes.bool,
  requireAdmin: PropTypes.bool,
  requireAuth: PropTypes.bool,
  hideWithAuth: PropTypes.bool,
  childLinks: PropTypes.arrayOf(
    PropTypes.shape({
      linkName: PropTypes.string,
      link: PropTypes.string,
      categoryName: PropTypes.string
    })
  ).isRequired
};

SubMenuMobile.defaultProps = {
  navDarkActive: false,
  requireAuth: false,
  hideWithAuth: false,
  requireAdmin: false
};
