import { css } from '@emotion/core';
import media from '../utils/media';
import colors from '../utils/colors';

export const svgStyles = css`
  opacity: 0.5;
  transition: opacity 0.15s ease-in;
  transition: color 0.15s ease-in;

  &:hover {
    text-decoration: none;
    box-shadow: none;
    opacity: 1;
    transition: opacity 0.15s ease-in;
  }
`;

export const iconTop = css`
  -webkit-transform: translateY(-70%);
  -ms-transform: translateY(-70%);
  transform: translateY(-70%);
  background-color: ${colors.tertiary};
  border-radius: 50% !important;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  text-align: center;
  transition: all 0.2s ease-in-out;
  width: 3.5rem;
  height: 3.5rem;
  font-size: 2.14286rem;
  ${media.small`
    width: 5rem;
    height: 5rem;
  `};
`;

export const iconTop2 = css`
  background-color: ${colors.tertiary};
  border-radius: 50% !important;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  text-align: center;
  transition: all 0.2s ease-in-out;
  width: 5rem;
  height: 5rem;
  font-size: 2.14286rem;
`;

export const iconTopFeather = css`
  position: relative;
  top: 50%;
  -webkit-transform: translateY(30%);
  -ms-transform: translateY(30%);
  transform: translateY(30%);
  z-index: 2;
  ${media.small`
    width: 50px;
    height: 50px;
  `};
`;

export const iconTopFeather2 = css`
  position: relative;
  top: 50%;
  z-index: 2;
  -webkit-transform: translateY(30%);
  -ms-transform: translateY(30%);
  transform: translateY(30%);
`;

export const listIcon = css`
  line-height: 15px;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  left: 0px;
  top: 0px;
  display: block;
  ${media.mid`
    line-height: 20px;
    width: 20px;
    height: 20px;
  `};
`;
export const listIconGreen = css`
  line-height: 15px;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  left: 0px;
  top: 0px;
  display: block;
  ${media.mid`
    line-height: 20px;
    width: 20px;
    height: 20px;
  `};
  color: green;
`;

export const iconTop3 = css`
  background-color: ${colors.tertiary};
  border-radius: 50% !important;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  text-align: center;
  transition: all 0.2s ease-in-out;
  width: 5rem;
  height: 5rem;
  font-size: 2.14286rem;
  margin-top: 35%;
  ${media.small`
    background-color: ${colors.tertiary};
    border-radius: 50% !important;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    text-align: center;
    transition: all 0.2s ease-in-out;
    width: 5rem;
    height: 5rem;
    font-size: 2.14286rem;
    margin-top: 15%;
  `};
  ${media.mid`
    background-color: ${colors.tertiary};
    border-radius: 50% !important;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    text-align: center;
    transition: all 0.2s ease-in-out;
    width: 5rem;
    height: 5rem;
    font-size: 2.14286rem;
    margin-top: 30%;
  `};
`;
