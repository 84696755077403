const colors = {
  example1: {
    primary: '#5c5c5b',
    secondary: '#fff',
    accent: '#333'
  },
  example2: {
    primary: '#fff',
    secondary: '#3f4761',
    tertiary: '#000033',
    accent: '#333',
    heading1: '#3f4761',
    heading2: '#000033',
    heading3: '#0A0833',
    heading4: '#0A0833'
  },
  projectred: {
    primary: '#fff',
    secondary: '#13283b',
    tertiary: '#e73e14',
    accent: '#333',
    heading1: '#3f4761',
    heading2: '#000033',
    heading3: '#0A0833',
    heading4: '#0A0833'
  }
};

export default colors.projectred;
